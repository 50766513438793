var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"30975320464418e73ac060096a9c28ded5a7a4b0"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from '@sentry/nextjs';
import { recommendBrowserMessage } from '~/libs/recommendBrowserMessage';
import { envManager } from './src/constants/envManager';

const status = recommendBrowserMessage();

Sentry.init({
  dsn: 'https://98fe7c148173b0e23cb837361b5e0843@o247126.ingest.us.sentry.io/4507547950907392',
  // Note: if you want to override the automatic release value, do not set a
  // `release` value here - use the environment variable `SENTRY_RELEASE`, so
  // that it will also get attached to your source maps
  environment: envManager.publicEnv.environment,
  integrations: status.usingRecommend ? [new Sentry.Replay()] : [],
  replaysSessionSampleRate: 0,
  replaysOnErrorSampleRate: 0.1,
});
